<template>
  <section class="containerAutocomplete">
    <section class="containerAutocomplete__inputSearch">
      <section class="containerAutocomplete__inputSearch--input">
        <section class="containerInput" ref="containerInput" :class="{'focus': searchTerm}">
          <span class="grey-icon-search"></span>
          <input class="containerInput__input"
            type="text"
            ref="inputSearch"
            :class="containerInputWidth"
            v-autowidth="{maxWidth: maxWidth, comfortZone: comfortZone, minWidth: minWidth}"
            v-model="searchTerm"
            placeholder="Search..."
            @keydown.enter="applySearch"
            @keydown.up="up"
            @keydown.down="down"
            @keyup="launchSuggestions()"
          />
          <hr class="containerInput__barAnimated">
          <div class="containerInput__autoLine"></div>
        </section>
      </section>
      <button class="containerAutocomplete__inputSearch--searchButton" @click="applySearch">
        <span class="text">Search</span>
        <span class="grey-icon-search"></span>
      </button>
    </section>
    <section class="containerAutocomplete__options" @mouseleave="resetActiveOver">
      <transition-staggered
        tag="ul"
        className="containerAutocomplete__options--list"
        type="horizontally"
        v-if="showSuggestions"
        ref="optionsList"
      >
        <li v-for="(item, index) in matches" :key="index" :data-index="index"
          @mouseover="removeSelected(index)"
          @click="itemSelected(index)"
          style="-webkit-box-orient: vertical;"
          class="item bookFont"
          :class="{'selected': selected === index, 'activeHover': activeHover === index}"
          :title="item.name"
        >
          {{item.name}}
        </li>
      </transition-staggered>
    </section>
  </section>
</template>

<script>
import { onMounted, nextTick, ref } from 'vue'
import { useStore } from 'vuex'
import VueInputAutowidth from 'vue-input-autowidth'

export default {
  name: 'autocomplete',
  directives: {

    /**
     * @description Workaround to VueInputAutowidth on vue3
     */
    autowidth: {
      beforeMount: VueInputAutowidth.bind,
      mounted: VueInputAutowidth.inserted,
      updated: VueInputAutowidth.componentUpdated,
      unmounted: VueInputAutowidth.unbind
    }
  },
  setup (props) {
    const store = useStore()
    const searchTerm = ref('')
    const requestControl = ref(null)
    const matches = ref([])
    const selected = ref(-1)
    const activeHover = ref(null)
    const showSuggestions = ref(false)
    const comfortZone = ref(40)
    const minWidth = ref('calc(100% - 53px)')
    const maxWidth = ref('calc(100% - 53px)')
    const itemHeight = ref(89)
    const desactivateRequest = ref(false)
    const previousInputValue = ref(null)
    const containerInput = ref(null)
    const inputSearch = ref(null)
    const optionsList = ref(null)
    const intervalProgress = ref(null)
    const containerInputWidth = ref('containerInputWidth')

    onMounted(() => {
      searchTerm.value = store.state.isThereSearch
      resizeSetUp()
      handleResize()
      inputSearch.value.focus()
    })

    /**
     * @description Handler to know the size of the screen
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
      })
    }

    /**
    * @description Handler to know the size of the screen
    */
    function resizeSetUp () {
      if (window.innerWidth <= 455) {
        comfortZone.value = 10
        maxWidth.value = 'calc(100% - 27px)'
      } else {
        comfortZone.value = 40
        maxWidth.value = 'calc(100% - 53px)'
      }
    }

    /**
     * @description Launches function to get search suggestion for user's input.
     */
    function launchSuggestions () {
      minWidth.value = inputSearch.value.value ? '0' : maxWidth.value
      containerInputWidth.value = inputSearch.value.value ? '' : 'containerInputWidth'
      nextTick(() => {
        if (previousInputValue.value !== inputSearch.value.value) {
          previousInputValue.value = inputSearch.value.value
          searchTerm.value = previousInputValue.value
          launchSearch(previousInputValue.value)
        }
      })
    }

    /**
     * @description Launches logic in the store to make an API request to get suggestions.
     * @param searchTermParam term given by the user.
     */
    function launchSearch (searchTermParam) {
      selected.value = selected.value === null ? -1 : selected.value
      store.commit({
        type: 'SET_SEARCH_VALUE',
        searchValue: searchTermParam,
        apply: false
      })
      if (searchTermParam.length < 3) {
        matches.value = []
        selected.value = -1
        showSuggestions.value = false
      }
      if (!desactivateRequest.value) {
        searchMatch(searchTermParam)
      }
    }

    /**
     * @description Get match results with typing's user
     * @param searchTermParam term given by the user.
     */
    function searchMatch (searchTermParam) {
      if (searchTermParam.length > 2) {
        clearTimeout(requestControl)
        requestControl.value = setTimeout(() => {
          store.dispatch({
            type: 'predictiveSearch'
          }).then(
            (response) => {
              if (!response) {
                return
              }
              if (!desactivateRequest.value) {
                matches.value = response.data
              }
              showSuggestions.value = searchTerm.value.length >= 3
            },
            (error) => {
              console.error(error)
            }
          )
        }, 500)
      }
    }

    /**
     * @description Apply search when user click on apply label
     * @event commit Set search value in store
     * @event dispatch Send request to get results
     * @event $Progress.start Start input progess bar
     */
    function applySearch () {
      inputSearch.value.blur()
      selected.value = selected.value === null ? -1 : selected.value
      desactivateRequest.value = true
      if (selected.value !== -1) {
        searchTerm.value = matches.value[selected.value].name
        setTimeout(() => {
          selected.value = -1
          matches.value = []
        })
      }

      store.commit({
        type: 'SET_SEARCH_VALUE',
        searchValue: searchTerm.value,
        apply: true
      })

      document.querySelector(':root').style.setProperty('--initialWidthAutoComplete', `${containerInput.value.offsetWidth}px`)

      store.commit({
        type: 'SET_EXACT_SEARCH',
        exactSearch: false
      })

      store.commit({
        type: 'SET_REFRESH_CARDS_FLAG',
        refreshCardsIdsNeeded: true
      })

      store.commit({
        type: 'SORT_TYPE',
        parameter: 'search.score()',
        order: 'desc'
      })

      initAnimation()

      setTimeout(() => {
        finishAnimation()
        setTimeout(() => {
          store.commit({
            type: 'UPDATE_URL',
            name: 'resultsSearch'
          })
        }, 500)
      }, 500)
    }

    /**
     * @description Select item when user click
     * @param index Index from list
     */
    function itemSelected (index) {
      selected.value = index
      applySearch()
    }

    /**
     * @description Event dispatch when user press up from keyboard
     */
    function up () {
      if (selected.value === -1) {
        return
      }
      selected.value = activeHover.value ? activeHover.value - 1 : selected.value - 1
      activeHover.value = null
      scrollToItem()
    }

    /**
     * @description Event dispatch when user press down from keyboard
     */
    function down () {
      if (selected.value >= matches.value.length - 1) {
        return
      }
      selected.value = activeHover.value ? activeHover.value + 1 : selected.value + 1
      activeHover.value = null
      scrollToItem()
    }

    /**
     * @description Position scroll if user down or up on list
     */
    function scrollToItem () {
      window.scrollTo(0, selected.value * itemHeight.value)
    }

    /**
     * @description Event dispatch when user perform hover in any item from the list
     * @param index Index item that user is hovering
     */
    function removeSelected (index) {
      selected.value = null
      activeHover.value = index
    }

    /**
     *  @description Event to reset the active over property when mouse is located outside the suggestions container
     */
    function resetActiveOver () {
      selected.value = -1
      activeHover.value = null
    }

    /**
     * @description Begins the animation of the bottom bar.
     */
    function initAnimation () {
      const styles = document.documentElement.style
      let progress = 1
      intervalProgress.value = setInterval(() => {
        progress *= 1.2
        styles.setProperty('--progressBarCounter', `${progress}%`)

        if (progress >= 99) {
          styles.setProperty('--progressBarCounter', '99%')
          clearInterval(intervalProgress.value)
        }
      }, 100)
    }

    /**
     * @description Finishes the animation of the bottom bar.
     */
    function finishAnimation () {
      clearInterval(intervalProgress.value)
      const styles = document.documentElement.style
      styles.setProperty('--progressBarCounter', '100%')
      setTimeout(() => {
        styles.setProperty('--progressBarCounter', '0%')
      }, 300)
    }

    return {
      store,
      searchTerm,
      requestControl,
      matches,
      selected,
      activeHover,
      showSuggestions,
      comfortZone,
      minWidth,
      maxWidth,
      itemHeight,
      containerInputWidth,
      desactivateRequest,
      previousInputValue,
      containerInput,
      inputSearch,
      optionsList,
      launchSuggestions,
      applySearch,
      itemSelected,
      up,
      down,
      removeSelected,
      resetActiveOver
    }
  }
}
</script>
