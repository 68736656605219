<template>
  <transition
    name="search-into"
    enter-active-class="animated fadeInLeft">
    <section class="containerHome" v-if="animationInto">
      <section class="containerHome__title">
        <span class="containerHome__title--label">GO.GREY</span>
      </section>
      <auto-complete></auto-complete>
    </section>
  </transition>
</template>

<script>
import { onMounted, onUpdated, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import autoComplete from '@/components/autoComplete/autoComplete'

export default {
  name: 'home',

  components: {
    autoComplete
  },
  setup (props) {
    const store = useStore()
    const animationInto = ref(false)

    onMounted(() => {
      isAnimation()
      setMobileHeight()
      if (navigator.userAgent.match(/iPhone/i)) {
        disableScrollIPhone()
      }
    })

    onUpdated(() => {
      toggleVisibilityFeedbackTour('isRenderReady', true)
    })

    onUnmounted(() => {
      document.documentElement.style.setProperty('--mobile-height-vh', null)
      document.ontouchmove = function (e) {
        return true
      }
      toggleVisibilityFeedbackTour('isPending', false)
    })

    /**
     * @description Change bool to show the animation at beginning
     */
    function isAnimation () {
      setTimeout(() => {
        animationInto.value = true
      })
    }

    /**
     * @description Sets size of height for mobile devices.
     */
    function setMobileHeight () {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--mobile-height-vh', `${vh}px`)
    }

    /**
     * @description Disable scroll if user into in iPhone
     */
    function disableScrollIPhone () {
      document.ontouchmove = function (e) {
        e.preventDefault()
        e.returnValue = false
      }
    }

    /**
     * @description Toggles the visiibility of feedback tour.
     * @param {isVisible} boolean to indicate if tour should be visible or not.
     */
    function toggleVisibilityFeedbackTour (property, isVisible) {
      store.commit({
        type: 'TOGGLE_ACTIVE_TOUR',
        tourName: 'GSV_feedbackButton',
        property: property,
        value: isVisible
      })
    }

    return {
      store,
      animationInto
    }
  }
}
</script>
